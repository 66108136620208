// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/login/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-page{position:relative;width:100vw;height:100vh;padding:1.5rem 0 0 0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;background-position:50%}.login-page .login-container{position:relative;width:3.33rem;height:2.66rem;margin:0 auto 0 auto;background:#fff;border-radius:.07rem;box-shadow:0 .01rem .02rem rgba(210,73,0,.46);z-index:999}.login-page .login-container .item-title{padding:.14rem 0 .24rem 0;text-align:center;font-size:.21rem;color:#f28246}.login-page .login-container .item-input{width:3.06rem;margin:.14rem auto 0 auto;border:.01rem solid #eee;border-radius:.02rem}.login-page .login-container .item-input i{vertical-align:middle;padding:0 .07rem;font-size:.16rem;color:#999}.login-page .login-container .item-input input{vertical-align:middle;width:2.74rem;line-height:.3rem;font-size:.1rem;color:#333;border:none}.login-page .login-container .item-checkbox{width:3.06rem;margin:.14rem auto 0 auto}.login-page .login-container .item-checkbox .van-checkbox .van-checkbox__label{font-size:.09rem;color:#999}.login-page .login-container .item-button{width:3.06rem;margin:.14rem auto 0 auto}.login-page .logo{position:absolute;bottom:.1rem;left:0;width:100%;text-align:center}.login-page .logo img{width:3.06rem}", ""]);
// Exports
module.exports = exports;
